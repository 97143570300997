/**
 * Uploads a file to a signed URL.
 * @param {File|Blob} file  The file to upload.
 * @param {string} signedUrl The signed URL to upload the file to.
 * @param {number} onProgress A callback that is called with the upload progress.
 * @returns A promise that resolves when the upload is complete.
 * @example
 * ```ts
 * const file = new File(["hello"], "hello.txt", { type: "text/plain" });
 * const signedUrl = "https://example.com/upload";
 * uploadFile(file, signedUrl, (progress) => {
 *      console.log(`Upload progress: ${progress}%`);
 *  }).then(() => {
 *      console.log("Upload complete");
 *  }).catch((error) => {
 *      console.error("Upload failed", error);
 *  });
 * ```
 */
export const uploadFile = (
  file: File | Blob,
  signedUrl: string,
  onProgress?: (progress: number) => void
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        onProgress?.(percentComplete);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        reject(new Error(`Upload failed with status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => {
      reject(new Error("An error occurred during the file upload."));
    };

    xhr.open("PUT", signedUrl, true);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.send(file);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      xhr.abort();
      reject(new Error("Upload timed out"));
    }, 60000);
  });
};
